.form-switch-md {
    padding-left: 2.5rem;
    min-height: 22px;
    line-height: 22px;

    .form-check-input {
        width: 40px;
        height: 20px;
        // left: -0.5rem;
        position: relative;
    }

    .form-check-label {
        vertical-align: middle;
        margin-left: 7px;
    }
}

.form-icon{
    position: relative;
    .form-control-icon {
        padding-left: 1.2rem*3;
        position: relative;
    }
    i {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 18px;
        display: flex;
        align-items: center;
    }
    &.right{
        .form-control-icon {
            padding-right: 1.2rem*3;
            padding-left: 1.2rem;
            position: relative;
        }
        i {
            left: auto;
            right: 18px;
        }
    }
}

.form-upload-multi{
    .form-upload-multi-ul{
        padding-left: 0px;
        margin-bottom: 4px;
        .form-upload-multi-li {
            height: 48px;
            min-width: 368px;
            padding: 0px 16px;
            margin-right: 24px;
            margin-bottom: 8px;
            display: inline-block;
            border-radius: 8px;
            background: rgba(7, 120, 255, 0.10);

            .form-upload-multi-content{
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                label{
                    color: #09101D;
                    font-family: 'Noto Sans Thai';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    cursor: pointer;
                }

                i{
                    color: #858C94;
                    font-size: 24px;
                    cursor: pointer;
                }

            }
        }
    }

    .form-upload-multi-file-input{
        display: none;
    }

    .form-upload-multi-file-label{
        cursor: pointer;
        padding: 4px 12px;
        margin-bottom: 4px;
        align-items: center;
        border-radius: 24px;
        background: #0778FF;
        color: #FFF;
        font-family: 'Noto Sans Thai';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;

        i{
            font-size: 16px;
        }
    }

    .form-upload-multi-file-is-sla-doc{
        cursor: pointer;
        padding: 4px 12px;
        margin-bottom: 4px;
        align-items: center;
        border-radius: 24px;
        background: #A5ABB3;
        color: #FFF;
        font-family: 'Noto Sans Thai';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;

        i{
            font-size: 16px;
        }
    }
}

.form-download-multi{
    .form-download-multi-ul{
        padding-left: 0px;
        .form-download-multi-li {
            height: 48px;
            // min-width: 368px;
            min-width: 338px;
            padding: 0px 16px;
            margin-right: 24px;
            margin-bottom: 8px;
            display: inline-block;
            border-radius: 8px;
            background: rgba(7, 120, 255, 0.10);

            .form-download-multi-content{
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                label{
                    padding-left: 3px;
                    padding-right: 3px;
                    color: #09101D;
                    font-family: 'Noto Sans Thai';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                }

                i{
                    color: #0778FF;
                    font-size: 18px;
                }

                .i-download{
                    font-size: 24px;
                    cursor: pointer;
                }
            }
        }
    }
}

.msg-require{
    color: #DA1414;
}

.invalid-feedback{
    color: #DA1414;
    border-radius: 8px;
    background-color: #FEEFEF;
    padding: 6px 8px 6px 32px;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: left calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}