@import "../scss/config/bootstrap.scss";
@import "../scss/config/app.scss";
@import "../scss/config/fonts.scss";

:root {
    --bodyColor: #fff;
}

body {
    * {
        outline: none;
    }
    background-color: var(--bodyColor);
    // background-image: url("../images/bg-img.png");
    // background-repeat: no-repeat;
    // background-position: left bottom;
    // background-position: calc(0% - 200px) bottom;
    // min-height: 100vh;
    // overflow: hidden;
}