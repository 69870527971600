.prefinal-contract-legal{
    .pcl-title{
        margin-bottom: 10px;
        color: #000;
        font-family: 'Noto Sans Thai';
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .pcl-form-header{
        margin-bottom: 24px;
        color: #000;
        font-family: 'Noto Sans Thai';
        font-size: 23px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .pcl-upload-contract-label{
        margin-bottom: 24px;
        color: #2C3A4B;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .pcl-memo-label{
        margin-top: 24px;
        margin-bottom: 24px;
        color: #2C3A4B;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }

    .pcl-upload-contract-div{
        margin-bottom: 16px;
    }

    .pcl-form-title{
        margin-bottom: 16px;
        color: #09101D;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .pcl-form-label{
        padding-left: 16px;
        color: #09101D;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
    
    .pcl-note-div{
        margin-bottom: 68px;
    }

    .pcl-btn-back{
        border-color: #FFF;
        background-color: #FFF;
        color: #858C94;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
    
    .pcl-btn-submit{
        border-color: #0778FF;
        border-radius: 8px;
        background-color: #0778FF;
        color: #FFF;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
    
}

.prefinal-contract-user{
    .pcu-title{
        margin-bottom: 10px;
        color: #000;
        font-family: 'Noto Sans Thai';
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .pcu-legal-div{
        margin-top: 5px;
        margin-bottom: 32px;
        padding: 32px;
        border-radius: 24px;
        border: 1px solid #0778FF;
        background: #FFF;
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);

        .pcu-legal-title{
            margin-bottom: 8px;
            color: #000;
            font-family: 'Noto Sans Thai';
            font-size: 23px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }

        .pcu-legal-desc{
            margin-bottom: 16px;
            color: #858C94;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        .pcu-legal-user{
            margin-bottom: 8px;
            color: #09101D;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        .pcu-legal-remark{
            margin-top: 16px;
            padding: 16px;
            border-radius: 8px;
            background-color: #FFF4EC;

            .pcu-legal-remark-title{
                margin-bottom: 8px;
                color: #09101D;
                font-family: 'Noto Sans Thai';
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;

                i {
                    margin-right: 4px;
                    font-size: 16px;
                    color: #B95000;
                }
            }

            .pcu-legal-remark-desc{
                color: #09101D;
                font-family: 'Noto Sans Thai';
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }
        }
    }

    .pcu-form-header{
        margin-bottom: 24px;
        color: #000;
        font-family: 'Noto Sans Thai';
        font-size: 23px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .pcu-form-title{
        margin-bottom: 16px;
        color: #09101D;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .pcu-form-label{
        padding-left: 16px;
        color: #09101D;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .pcu-note-div{
        margin-bottom: 68px;
    }

    .pcu-btn-back{
        border-color: #FFF;
        background-color: #FFF;
        color: #858C94;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
    
    .pcu-btn-submit{
        border-color: #0778FF;
        border-radius: 8px;
        background-color: #0778FF;
        color: #FFF;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
}

.prefinal-contract-reject{
    .pcr-title{
        margin-bottom: 10px;
        color: #000;
        font-family: 'Noto Sans Thai';
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .pcr-reject-by{
        margin-bottom: 24px;
        padding: 32px 56px;
        border-radius: 24px;
        background-color: #FFF4EC;

        .pcr-reject-title{
            margin-bottom: 16px;
            color: #000;
            font-family: 'Noto Sans Thai';
            font-size: 23px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;

            i{
                margin-right: 8px;
                font-size: 24px;
                color: #B95000;
            }
        }

        .pcr-reject-reason-label{
            margin-bottom: 8px;
            color: #545D69;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        .pcr-reject-reason-detail{
            margin-bottom: 0px;
            color: #09101D;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }
    }

    .pcr-form{
        .pcr-form-legal-title{
            margin-bottom: 24px;
            color: #000;
            font-family: 'Noto Sans Thai';
            font-size: 23px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }

        .pcr-form-legal-file-label{
            margin-bottom: 24px;
            color: #2C3A4B;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        .pcr-form-memo-label{
            margin-top: 24px;
            margin-bottom: 24px;
            color: #2C3A4B;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        .pcr-form-user-title{
            margin-bottom: 24px;
            color: #000;
            font-family: 'Noto Sans Thai';
            font-size: 23px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }

        .pcr-form-head-input-label{
            margin-bottom: 16px;
            color: #09101D;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        .pcr-form-label{
            padding-left: 16px;
            color: #09101D;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        .pcr-form-legal-file-div{
            margin-bottom: 24px;
        }

        .pcr-form-legal-last-div{
            margin-bottom: 32px;
        }

        .pcr-form-user-div{
            padding-top: 32px;
            border-top: 2px dashed #DADEE3;
        }

        .pcr-form-user-last-div{
            margin-bottom: 68px;
        }

        .pcr-form-btn-back{
            border-color: #FFF;
            background-color: #FFF;
            color: #858C94;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }
        
        .pcr-form-btn-submit{
            border-color: #0778FF;
            border-radius: 8px;
            background-color: #0778FF;
            color: #FFF;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }

    }

}