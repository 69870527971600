//form-request
.txt-placeholder{
  font-family: 'Noto Sans Thai';
}

.request_label {
    padding-left: 15px;
    font-family: 'Noto Sans Thai';
}

.request_label_nowrap {
  padding-left: 15px;
  text-overflow: ellipsis; 
  white-space: nowrap;
  font-family: 'Noto Sans Thai';
}

.form-check-label {
  font-family: 'Noto Sans Thai';
}

.form-export-file-label{
  cursor: pointer;
  padding: 6px 16px;
  margin-bottom: 4px;
  align-items: center;
  border-radius: 24px;
  background: #0778FF;
  color: #FFF;
  font-family: 'Noto Sans Thai';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  float: right;

  i{
      font-size: 16px;
  }
}

.form-export-file-input{
  display: none;
}

.page-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  // z-index: 1002;
  z-index: 0;
  background-color: #FFF;
  transition: all 0.1s ease-out;
  border-top: 2px solid #E5E5E5;

  &.footer-shadow {
      box-shadow: 0 1px 2px rgba(56,65,74,0.15);
  }

  @media (min-width: 768px) {
      left: 218px;
  }
}

.layout-width-footer {
  max-width: 100%;
  margin: 0 auto;
}

.navbar-footer {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 90px;
  padding: 0 17rem 0 calc(1.5rem / 2);

  @media (max-width: 767.98px) {
      padding: 0 calc(1.5rem / 2) 0 calc(1.5rem / 2);
  }
}

.navbar-footer2 {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 90px;
  padding: 0 1.5rem 0 1.5rem;

  @media (max-width: 767.98px) {
      padding: 0 calc(1.5rem / 2) 0 calc(1.5rem / 2);
  }
}

.span-title {
  font-size: 23px;
  font-weight: 600;
  font-family: 'Noto Sans Thai';
}

.label-title {
  padding-left: 15px;
  color: #09101D;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-family: 'Noto Sans Thai';
}

.label-title-approver-comment {
  padding-left: 30px;
  color: #09101D;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-family: 'Noto Sans Thai';
}

.label-title-confidential {
  padding-left: 10px;
  color: #09101D;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-family: 'Noto Sans Thai';
}

.label-title-date {
  padding-left: 15px;
  margin-top: 10px;
  font-family: 'Noto Sans Thai';
}

.icon-hint {
  font-size: 18px;
  color: #6D7580;
  cursor: pointer;
  padding-left: 10px;
}

.icon-hint-no-padding {
  font-size: 18px;
  color: #6D7580;
  cursor: pointer;
}

.icon-hint-right {
  font-size: 18px;
  color: #6D7580;
  float: right;
  cursor: pointer;
}

.msg-show-hint {
  font-family: 'Noto Sans Thai';
}

.msg-icon-hint {
  font-size: "18px";
  color: #FDCC4A;
}

.line-border {
  border: none;
  border-top: 1px dashed #0778FF80;
  margin: 20px 0;
}

.btn-save-style {
  margin-right: 10px;
  border-radius: 8px;
}

.btn-submit-style {
  border-radius: 8px;
}

.btn-return-style {
  margin-right: 5px;
  color: #0778FF;
}

.remark-content {
  background-color: #FFF4EC;
  margin-top: 20px;
  border-radius: 12px;
}

.icon-remark-color {
  font-size: 18px;
  color: #B95000;
}

.span-remark {
  font-size: 14px;
  color: #09101D;
  font-family: 'Noto Sans Thai';
}

.language-none {
  display: none;
}

.language-block {
  display: block;
}

.span-type-request {
  font-size: 23px;
  font-family: 'Noto Sans Thai';
  font-weight: 600;
}

.span-type-request-note {
  color:#545D69;
  font-family: 'Noto Sans Thai';
}

.span-term {
  padding-left: 15px;
  color:#545D69;
  font-family: 'Noto Sans Thai';
}

.input-radio-check-term-1 {
  margin-left: 15px;
  margin-top: 14px;
}

.input-radio-check-term-2 {
  margin-left: 15px;
}

.term-form-check-label {
  padding-left: 15px;
  font-family: 'Noto Sans Thai';
}

.label-ddl {
  padding-left: 15px;
  font-family: 'Noto Sans Thai';
}

.button-continue {
  border-radius: 8px;
}

.modal-alert {
  font-size: 35px;
  float: left;
  font-family: 'Noto Sans Thai';
  width: 35px;
  height: 35px;
  color: #0778FF;
}

.modal-note {
  font-size: 21px;
  text-align: left;
  font-family: 'Noto Sans Thai';
}

.modal-content-approve {
  text-align: left;
  color: #09101D;
  font-family: Noto Sans Thai;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.8;
}

.arrow-right-calendar {
  width: 20px;
  padding-left: 0px;
  padding-right: 13px;
  padding-bottom: 15px;
  font-size: 30px;
  float:left;
  color: #858C94 ;
}

.form-term-end-date {
  width: 42.4%;
}

.form-term-start-date {
  width: 41%;
  padding-right: 0px;
}

.remark-color {
  color: #000000;
  font-family: 'Noto Sans Thai';
}

.header-form {
  margin-right: 39px;
  margin-top: 10px;
}

.approver-header-content {
    margin: 5px 40px 20px 15px;
    color: #000000;
    font-family: 'Noto Sans Thai';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.approver-comment {
  background-color: #FDCC4A1A;
}

.page-content-approver {
  flex-shrink: 0;
  border-radius: 12px;
  background: #FDCC4A1A;
  padding: 15px 0;
}

.txt-approver-comment {
  font-family: 'Noto Sans Thai';
  margin-left: 15px;
}

.approver-col {
  width: 96%;
}

.radio-term {
  margin-top: 10px;
  margin-left: 15px;
}

.radio-vat {
  margin-top: 15px;
  margin-left: 15px;
}

.alert-validation-vat {
  margin-bottom: 10px;
}

.alert-validation-approver-comment {
  display: block;
  margin-left: 15px;
  font-family: 'Noto Sans Thai';
}

.step-text-active {
  font-size: 14px;
  margin-top: 7px; 
  font-weight: 600;
  color: #000000;
}

.step-text-inactive {
  font-size: 14px; 
  margin-top: 7px; 
  font-weight: normal;
  color: inherit;
}

.NavItem {

  text-align: center; 
  width: 160px;
  height: 80px;
}

.circle {
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  /* สไตล์ที่ต้องการให้ circle small ก่อน */
}

.stepper-span-1-active {
  background-color: white;
  color: #EFF4FF;
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stepper-span-1-inactive {
  background-color: white;
  color: #0778FF;
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stepper-span-2-active {
  background-color: #EFF4FF;
  color: #EFF4FF;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stepper-span-2-inactive {
  background-color: rgb(239 244 255 / 20%);
  color: #0778FF;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stepper-span-3-active {
  background-color: #0778FF;
  color: #EFF4FF;
  width: 40px;
  height: 40px;
  // background-color: #0778FF;
  // color: #F0F8FF;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stepper-span-3-inactive {
  background-color: #EFF4FF;
  color: #0778FF;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stepper-span-complete {
  color: #FFF !important;
  background-color: #3DD598 !important;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stepper-span-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.progress-bar-wrapper {
  position: absolute;
  bottom: 56px;
  width: 90%;
  background-color: rgb(239, 244, 255);
}

.stepper-nav-item {
  z-index: 1000;
  text-align: center;
  width: 10%;
  height: 80px;
  max-width: 200px;
}

.stepper-nav-link-active {
  position: relative;
  background-color: white;
  color: #0778FF;
  align-items: center;
  justify-content: center;
  width: 3em;
  height: 3em;
  font-size: 1rem;
  border: none;
  border-radius: 50%;
  font-weight: 400;
  flex: none;
  display: flex;
  z-index: 2000;
  max-width: 3em;
  max-height: 3em;
}

.stepper-nav-link-inactive {
  position: relative;
  background-color: white;
  color: #EFF4FF;
  align-items: center;
  justify-content: center;
  width: 3em;
  height: 3em;
  font-size: 1rem;
  border: none;
  border-radius: 50%;
  font-weight: 400;
  flex: none;
  display: flex;
  z-index: 2000;
  max-width: 3em;
  max-height: 3em;
}

.stepper-block {
  padding: 24px;
}

/* Media query สำหรับหน้าจอที่มีความกว้าง 1980px ขึ้นไป */
@media (min-width: 1900px) {
  .progress-bar-wrapper {
    left: 23%;
    transform: translateX(-20%);
  }
  .stepper-nav-item {
    max-width: 100%;
  }
}

/* Media query สำหรับหน้าจอที่มีความกว้าง 1360px แต่น้อยกว่า 1980px */
@media (min-width: 1360px) and (max-width: 1899px) {
  .progress-bar-wrapper {
    left: 18%; 
    transform: translateX(-16%); 
  }
}

.progress-bar {
  height: 2px;
}

@media (max-width: 1070px) {
  .step-text-active,
  .step-text-inactive {
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .stepper-span-1-active,
  .stepper-span-1-inactive {
    width: 30px;
    height: 30px;
  }
  
  .stepper-span-2-active,
  .stepper-span-2-inactive {
    width: 20px;
    height: 20px;
  }

  .stepper-span-3-active,
  .stepper-span-3-inactive {
    width: 18px;
    height: 18px;
  }

  .step-text-active,
  .step-text-inactive {
    font-size: 6px;
    margin-top: 0px;
  }
  .stepper-block {
    padding: 0px;
  }
  .stepper-span-content {
    font-size: 8px;
  }
  .stepper-span-complete {
    width: 18px;
    height: 18px;
  }
}

@media (min-width: 577px) and (max-width: 976px) {
  .stepper-span-1-active,
  .stepper-span-1-inactive {
    width: 40px;
    height: 40px;
  }
  
  .stepper-span-2-active,
  .stepper-span-2-inactive {
    width: 30px;
    height: 30px;
  }

  .stepper-span-3-active,
  .stepper-span-3-inactive {
    width: 20px;
    height: 20px;
  }

  .stepper-span-complete {
    width: 20px;
    height: 20px;
  }

  .step-text-active,
  .step-text-inactive {
    font-size: 8px;
    margin-top: 0px;
  }
  .stepper-block {
    padding: 0px;
  }
  .stepper-span-content {
    font-size: 10px;
  }
  
}
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
  
    .stepper-span-1-active,
    .stepper-span-1-inactive {
      width: 40px;
      height: 40px;
    }
    
    .stepper-span-2-active,
    .stepper-span-2-inactive {
      width: 30px;
      height: 30px;
    }

    .stepper-span-3-active,
    .stepper-span-3-inactive {
      width: 20px;
      height: 20px;
    }

    .stepper-span-complete {
      width: 20px;
      height: 20px;
    }
  
    .step-text-active,
    .step-text-inactive {
      font-size: 8px;
      margin-top: 0px;
    }
    .stepper-block {
      padding: 0px;
    }
    .stepper-span-content {
      font-size: 10px;
    }
}
