//
// _nav.scss
//
.nav-tabs-dashboard {
    border-bottom: 1px solid #DADEE3;

    .nav-item {
        position: relative;

        .nav-link {
            border: none;
            color: #545D69;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            padding-bottom: 9px;

            &::after {
                content: "";
                background-color: #0778FF;
                height: 3px;
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 0;
                transition: all 250ms ease 0s;
                transform: scale(0);
            }

            &.active {
                color: #0778FF;

                &:after {
                    transform: scale(1);
                }
            }

            .nav-noti{
                margin-left: 10px;
                border-radius: 12px;
                background-color: #EFF4FF;
                text-align: center;
                display: inline-block;
                label {
                    min-width: 30px;
                    height: 20px;
                    vertical-align: middle;
                    color: #0778FF;
                    font-family: 'Noto Sans Thai';
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 16px;
                    padding: 0 8px;
                }
            }
        }
    }

    &.card-header-tabs {
        margin-top: -1rem;

        .nav-link {
            padding: 1rem 1rem;
        }
    }
}

.nav-tabs-form {
    border-bottom: 1px solid #DADEE3;
    margin-left: 3%;
    margin-right: 3%;
    .nav-item {
        position: relative;
        flex-grow: 0.16;
        flex-direction: column;
        .nav-link {
            border: none;
            color: #545D69;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            padding-bottom: 9px;

            &::after {
                content: "";
                background-color: #0778FF;
                height: 3px;
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 0;
                transition: all 250ms ease 0s;
                transform: scale(0);
            }

            &.active {
                color: #0778FF;

                &:after {
                    transform: scale(1);
                }
            }

            .nav-noti{
                margin-left: 10px;
                border-radius: 12px;
                background-color: #EFF4FF;
                text-align: center;
                display: inline-block;
                label {
                    min-width: 30px;
                    height: 20px;
                    vertical-align: middle;
                    color: #0778FF;
                    font-family: 'Noto Sans Thai';
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 16px;
                    padding: 0 8px;
                }
            }
        }
    }

    &.card-header-tabs {
        margin-top: -1rem;

        .nav-link {
            padding: 1rem 1rem;
        }
    }
}