.dashboard-to-do-list{
    .dtdl-title{
        color: #000;
        font-family: 'Noto Sans Thai';
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .dtdl-content{
        margin-top: 28px;
        border-radius: 24px;
        border: 1px solid #DADADA;

        .dtdl-card-body{
            padding: 0.5rem 1rem;
        }

        .dtdl-row{
            // min-height: 136px;
            min-height: 106px;

            .dtdl-col{
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
        }

        .dtdl-card{
            min-height: 88px;
            margin: 4px;
            border-radius: 24px;
            flex-shrink: 0;

            .dtdl-card-content{
                width: 100%;
                min-height: 88px;
                padding-left: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
    
                .dtdl-card-icon{
                    width: 56px;
                    height: 56px;
                    border-radius: 16px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
    
                    i {
                        font-size: 24px;
                        color: #FFF;
                    }
                }
    
                .dtdl-card-content-detail {
                    padding-left: 10px;
                    padding-right: 10px;
                    .dtdl-status-title{
                        min-width: 120px;
                        color: #000;
                        font-family: 'Noto Sans Thai';
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                    }
        
                    .dtdl-status-amount{
                        color: #000;
                        font-family: 'Noto Sans Thai';
                        font-size: 33px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 36px;
                    }
        
                    .dtdl-status-msg{
                        margin-left: 8px;
                        color: #858C94;
                        font-family: 'Noto Sans Thai';
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                    }
        
                }
            }

            .dtdl-icon-waiting-for-approve{
                background-color: #F8B9FF;
            }

            .dtdl-icon-in-progress{
                background-color: #FF974A;
            }

            .dtdl-icon-reject{
                background-color: #6844FF;
            }

            .dtdl-icon-complete{
                background-color: #3DD598;
            }
        }

        .dtdl-div-waiting-for-approve{
            background-color : rgba(248, 185, 255, 0.20);
        }

        .dtdl-div-in-progress{
            background-color : rgba(255, 151, 74, 0.15);
        }

        .dtdl-div-reject{
            background-color : rgba(104, 68, 255, 0.15);
        }

        .dtdl-div-complete{
            background-color : rgba(61, 213, 152, 0.15);
        }
    }
}

.dashboard-tracking-contract{
    margin-top: 32px;
    
    .dtc-title{
        color: #000;
        font-family: 'Noto Sans Thai';
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .dtc-content{
        margin-top: 28px;
    }

    .dtc-table-status{
        min-height: 24px;
        label{
            color: #394452;
            font-family: 'Noto Sans Thai';
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            vertical-align: middle;
        }

        i{
            font-size: 20px;
            margin-right: 5px;
            vertical-align: middle;
        }

        .dtc-ts-wating-for-approve{
            color:#F8B9FF;
        }

        .dtc-ts-in-progress{
            color:#FF974A;
        }

        .dtc-ts-reject{
            color:#6844FF;
        }

        .dtc-ts-complete{
            color:#3DD598;
        }
    }

    .dtc-table-level{
        padding: 4px 12px;
        border-radius: 24px;
        text-align: center;
        color: #FFF;
        font-family: 'Noto Sans Thai';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    .dtc-table-level-confidential{
        background-color: #B95000;
    }

    .dtc-table-level-normal{
        background-color: #FDCC4A;
    }
}