//
// _layout.scss
//
.main-content {
    transition: all 0.1s ease-out;

    @media (min-width: 768px) {
        margin-left: 218px;
    }

    @media (max-width: 767.98px) {
        margin-left: 0;
    }
}

.page-content {
    padding: calc(100px + 1.5rem) calc(1.5rem / 2) 60px calc(1.5rem / 2);
}
// ++++ Start Top bar ++++
.page-topbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1002;
    background-color: #FFF;
    transition: all 0.1s ease-out;
    border-bottom: 2px solid #E5E5E5;;

    &.topbar-shadow {
        box-shadow: 0 1px 2px rgba(56,65,74,0.15);
    }

    @media (min-width: 768px) {
        left: 218px;
    }
}

.layout-width {
    max-width: 100%;
    margin: 0 auto;
}

.navbar-header {
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: 100px;
    padding: 0 1.5rem 0 calc(1.5rem / 2);

    @media (max-width: 767.98px) {
        padding: 0 calc(1.5rem / 2) 0 calc(1.5rem / 2);
    }
}

.header-item {
    height: 100px;
    display: flex;
    align-items: center;
}

.topbar-user {
    @media (min-width: 768px) {
        background-color: #FFF;
    }

    .dropdown-menu {
        top: 6px !important;
    }

    .user-name-text {
        color: #000;

        font-family: 'Noto Sans Thai';
        // font-size: 13px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 153.846% */
    }
}

.hamburger-icon {
    width: 20px;
    height: 14px;
    position: relative;
    display: inline-block;

    span {
        background-color: #000;
        position: absolute;
        border-radius: 2px;
        transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
        width: 100%;
        height: 2px;
        display: block;
        left: 0px;

        &:nth-child(1) {
            top: 0;
            // width: 80%;
        }

        &:nth-child(2) {
            top: 6px;
        }

        &:nth-child(3) {
            bottom: 0;
            // width: 60%;
        }
    }

    @media (min-width: 768px) {
        display: none;
    }
}

.hamburger-cursor {
    cursor: pointer !important;

    @media (min-width: 768px) {
        cursor: default !important;
        display: none;
    }
}

.topbar-span-title{
    @media (min-width: 768px) {
        margin-left: 1rem;
    }
    
    .topbar-title{
        color: #000;
        
        font-family: 'Noto Sans Thai';
        font-size: 33px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
    }
}


// ++++ End Top bar ++++

// ++++ Start Side bar ++++
.app-menu {
    .row {
        margin: 0;

        >* {
            width: 100%;
            padding: 0;
        }
    }

    @media (max-width: 767.98px) {
        margin-left: -100%;
        padding: 10px 0 20px 0;
    }
}

.navbar-menu {
    width: 218px;
    z-index: 1002;
    background: #F4F6F9;
    border-right: 1px solid #E5E5E5;
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 0;
    box-shadow: 0 2px 4px rgba(15,34,58,0.12);
    padding: 0 0 calc(70px + 25px) 0;
    transition: all 0.1s ease-out;

    .container-fluid {
        padding: 0;
    }

    .navbar-nav {
        .nav-link {
            display: flex;
            align-items: center;
            padding: 0.625rem 1.5rem;
            color: #858C94;
            font-size: 0.9375rem;
            font-family: 'hkgrotesk', sans-serif;

            &.active {
                color: #09101D;
            }

            &:hover {
                color: #09101D;
            }

            i {
                display: inline-block;
                min-width: 1.75rem;
                font-size: 18px;
                line-height: inherit;
            }

            svg {
                width: 18px;
                margin-right: 0.665rem;
                color: #858C94;
            }

            .badge {
                margin-left: auto;
                margin-right: -2px;
                z-index: 1;
            }

            &[data-bs-toggle="collapse"] {
                &:after {
                    display: block;
                    content: "\F0142";
                    font-family: "Material Design Icons";
                    margin-left: auto;
                    transition: transform 0.2s;
                    font-size: 1.05rem;
                    position: absolute;
                    right: 18px;
                    // color: #F00;
                }
        
                &[aria-expanded="true"] {
                    // color: #F00;
        
                    .icon-dual {
                        // color: #F00;
                        fill: rgba($primary, 0.16);
                    }
        
                    &:after {
                        transform: rotate(90deg);
                        // color: #F00;
                    }
        
                    &:before {
                        opacity: 1;
                        // background-color: #F00;
                    }
                }
            }

        }

        .menu-link {
            &.active {
                border-left: 4px solid #0778FF;
            }
        }

        .nav-sm {
            // padding-left: 1.75rem;
            padding-left: 2rem;

            // .nav-link {
            //     padding: 0.55rem 1.5rem  !important;
            //     color: #878a99;
            //     white-space: none;
            //     position: relative;
            //     font-size: 0.813rem;
            //     font-family: 'Poppins', sans-serif;;

            //     &:before {
            //         content: "";
            //         width: 6px;
            //         height: 1.5px;
            //         background-color: #878a99;
            //         position: absolute;
            //         left: 2px;
            //         top: 16.5px;
            //         transition: all 0.4s ease-in-out;
            //         opacity: 0.5;
            //     }

            //     &:hover {
            //         color: #09101D;

            //         &:before {
            //             background-color: #09101D  !important;
            //             opacity: 1;
            //         }
            //     }

            //     &.active {
            //         color: #09101D;

            //         &:before {
            //             background-color: #09101D;
            //         }
            //     }
            // }

            // .nav-sm {
            //     padding-left: 15px;
            //     .nav-link {
            //         &:before {
            //             height: 5px;
            //             width: 5px;
            //             left: 5px;
            //             border-radius: 50%;
            //             background-color: transparent;
            //             border: 1px solid;
            //             top: 16px;
            //         }
            //     }
            // }
        }
    }
}

.navbar-brand-box {
    padding: 0 1.3rem;
    text-align: center;
    transition: all 0.1s ease-out;

    @media (max-width: 767.98px) {
        display: none;
    }
}

.logo {
    line-height: 100px;

    .logo-sm {
        display: none;
    }
}

.vertical-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(#212529, 0.35);
    z-index: 1003;
    display: none;
  }

// Mobile Menu
.vertical-sidebar-enable {
    .vertical-overlay {
        display: block;
    }

    .app-menu {
        margin-left: 0 !important;
        z-index: 1004;
    }
}
// ++++ End Side bar ++++