//
// _table.scss
//
.table-req{
    border-collapse: separate;
    border-spacing: 0px 0.5em;
    margin-bottom: 4px !important;
    thead {
        tr {
            height: 40px;
            border-radius: 16px;
            background-color: #F4F6F9;
        }
        th:first-child {
            border-radius: 16px 0 0 16px;
        }
        th:last-child {
            border-radius: 0 16px 16px 0;
        }
        th {
            cursor: pointer;
        }
    }
    tbody {
        tr {
            height: 68px;
            border-radius: 16px;
            background-color: #FFF;
        }
        td:first-child {
            border-radius: 16px 0 0 16px;
            border-left: 1px solid #E5E5E5;
        }
        td:last-child {
            border-radius: 0 16px 16px 0;
            border-right: 1px solid #E5E5E5;
        }
        td{
            border-top: 1px solid #E5E5E5;
            border-bottom: 1px solid #E5E5E5;
            color: #696974;
            font-family: 'Noto Sans Thai';
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }
}

.show-result-table{
    color: #696974;
    font-family: "Noto Sans Thai";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}