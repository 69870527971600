.wrapper {
    padding-bottom: 45px;
}

.editor {
    width: 100%;
    min-width: 600px;
    max-width: 1100px;
    min-height: 200px;
    height: 200px;
}

.ql-editor {
    font-size: 16px;
    tab-size: 4;
}

.ql-container {
    font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {
    content: attr(data-value) !important;
}

.ql-indent-1 {
    margin-left: 20px;
}

.ql-indent-2 {
    margin-left: 40px;
}

.ql-indent-3 {
    margin-left: 60px;
}

.ql-indent-4 {
    margin-left: 80px;
}

.ql-indent-5 {
    margin-left: 100px;
}

.ql-indent-6 {
    margin-left: 120px;
}

.ql-indent-7 {
    margin-left: 140px;
}

.ql-indent-8 {
    margin-left: 160px;
}