// 
// _pagination.scss
// 

// Pagination Separated
.pagination-separated {
    .page-item {
        .page-link {
            margin-left: 0.35rem;
            border-radius: 0.25rem;
            font-family: "Noto Sans Thai";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }
}

// Pagination rounded (Custom)
.pagination-rounded {
    .page-link {
        border-radius: 30px !important;
        margin: 0 3px !important;
        border: none;
        min-width: 32px;
        min-height: 32px;
        text-align: center;
    }

    &.pagination-sm {
        .page-link {
            min-width: 25px;
            min-height: 25px;
        }
    }
}

.page-item.active {
    .page-link{
        box-shadow: none;
    }
}