.request-final-approver{
    margin-top: 20px;

    .rfa-container{
        display: grid;
        grid-template-columns: 28px auto;
        grid-template-rows: auto;
        grid-template-areas: 
            "desc-icon desc-detail"
            "desc-line desc-space";
        // grid-row-gap: 8px;
        // margin-bottom: 8px;
    }

    .rfa-item-desc-icon{
        grid-area: desc-icon;
        justify-self: center;
        align-self: center;
        
        .rfa-desc-icon-circle{
            width: 24px;
            height: 24px;
            background-color: #0778FF1A;
            border: solid 1px #0778FF1A;
            border-radius: 100%;
            text-align: center;
            color: #0778FF;
            font-family: 'Noto Sans Thai';
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }

        .active{
            background-color: #0778FF;
            border: solid 1px #0778FF;
            color: #FFFFFF;
        }

        .sucess{
            background-color: #3DD598;
            border: solid 1px #3DD598;
            color: #FFFFFF;
        }
    }

    .rfa-item-desc-detail{
        grid-area: desc-detail;
        justify-self: start;
        align-self: center;
        color: #09101D;
        margin-left: 20px;

        .rfa-item-desc-detail-label{
            margin-bottom: 0px;
            font-family: 'Noto Sans Thai';
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
        }

        .active{
            color: #0778FF;
        }

        .sucess{
            color: #09101D;
        }
    }

    .rfa-item-desc-line{
        grid-area: desc-line;
        height: 100%;
        justify-self: center;
        align-self: start;
        background-color: rgba(7, 120, 255, 0.10);

        .rfa-desc-icon-line{
            width: 2px;
        }
    }

    .rfa-item-desc-space{
        grid-area: desc-space;
        height: 20px;
        justify-self: start;
        align-self: center;
        background-color: #F00;
    }

}