.signed-contract{
    .sc-title{
        margin-bottom: 10px;
        color: #000;
        font-family: 'Noto Sans Thai';
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .sc-msg-head{
        padding: 36px 56px;
        margin-bottom: 25px;
        border-radius: 24px;
        background-color: rgba(221, 238, 255, 0.60);
        .sc-msg-head-title{
            margin-bottom: 8px;
            color: #000;
            font-family: 'Noto Sans Thai';
            font-size: 23px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;

            i{
                margin-right: 8px;
                font-size: 24px;
                color: #0778FF;
            }
        }

        .sc-msg-head-title2{
            margin-left: 32px;
            margin-bottom: 16px;
            color: #000;
            font-family: 'Noto Sans Thai';
            font-size: 23px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;

            @media (max-width: 767.98px) {
                margin-left: 0px;
            }
        }

        .sc-msg-head-detail{
            margin-left: 32px;
            margin-bottom: 0px;
            color: #09101D;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;

            @media (max-width: 767.98px) {
                margin-left: 0px;
            }
        }
    }

    .sc-form{
        padding: 48px 56px;
        border-radius: 24px;
        background-color: #FFF;
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
        .sc-form-title{
            border-bottom: 2px dashed #DADEE3;
            margin-bottom: 16px;
            p{
                margin-bottom: 16px;
                color: #000;
                font-family: 'Noto Sans Thai';
                font-size: 23px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
            }
        }

        .sc-form-detail{
            border-bottom: 2px dashed #DADEE3;
            margin-bottom: 16px;

            .sc-form-detail-contract-label{
                margin-bottom: 8px;
                color: #545D69;
                font-family: 'Noto Sans Thai';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            .sc-form-detail-contract-detail{
                margin-bottom: 16px;
                color: #09101D;
                font-family: 'Noto Sans Thai';
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
            }

            .sc-form-detail-final-contract-label{
                margin-bottom: 16px;
                color: #545D69;
                font-family: 'Noto Sans Thai';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            .sc-form-detail-memo-label{
                margin-top: 18px;
                margin-bottom: 16px;
                color: #545D69;
                font-family: 'Noto Sans Thai';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
        }

        .sc-form-workarea{
            .sc-form-workarea-file-label{
                margin-bottom: 12px;
                color: #545D69;
                font-family: 'Noto Sans Thai';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
        }

        .sc-form-btn-submit{
            border-color: #0778FF;
            border-radius: 8px;
            background-color: #0778FF;
            color: #FFF;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }

        .sc-form-btn-back{
            border-color: #FFF;
            background-color: #FFF;
            color: #858C94;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }

    }

    .sc-history{
        padding: 0px 56px 0px 56px;
        margin-top: 32px;
        margin-bottom: 68px;
        .sc-history-title{
            padding-top: 32px;
            border-top: 2px dashed rgba(7, 120, 255, 0.50);
            margin-bottom: 36px;
            color: #000;
            font-family: 'Noto Sans Thai';
            font-size: 23px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }
    }

}