.final-contract{
    .fc-title{
        margin-bottom: 10px;
        color: #000;
        font-family: 'Noto Sans Thai';
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .fc-detail{
        padding: 56px;
        margin-bottom: 32px;
        border-radius: 24px;
        background: #FFF;
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);

        .fc-detail-title{
            padding-bottom: 16px;
            margin-bottom: 16px;
            color: #000;
            font-family: 'Noto Sans Thai';
            font-size: 23px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            border-bottom: 2px dashed #DADEE3;
        }

        .fc-detail-file-label{
            margin-bottom: 16px;
            color: #545D69;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        .fc-detail-contractno-label{
            margin-bottom: 8px;
            color: #545D69;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }

        .fc-detail-contractno-value{
            margin-bottom: 16px;
            color: #09101D;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
        }

        .fc-detail-memo-label{
            margin-bottom: 16px;
            color: #545D69;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

    }

    .fc-workarea{
        padding: 0px 56px 6px 56px;
        margin-bottom: 32px;
        border-bottom: 2px dashed rgba(7, 120, 255, 0.50);
        .fc-workarea-title{
            color: #000;
            font-family: 'Noto Sans Thai';
            font-size: 23px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }

        .fc-workarea-note-div{
            // margin-bottom: 16px;
            margin-bottom: 5px;
        }

        .fc-workarea-note-label{
            color: #09101D;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        .fc-workarea-consent-label{
            margin-left: 12px;
            color: #09101D;
            font-family: 'Noto Sans Thai';
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }

        .fc-workarea-consent-label-desc{
            color: #09101D;
            font-family: 'Noto Sans Thai';
            font-size: 13px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px;
        }
    }

    .fc-btn-back{
        border-color: #FFF;
        background-color: #FFF;
        color: #858C94;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .fc-btn-reject{
        border-color: #FFF;
        background-color: #FFF;
        color: #B95000;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
    
    .fc-btn-approve{
        margin-left: 16px;
        border-color: #0778FF;
        border-radius: 8px;
        background-color: #0778FF;
        color: #FFF;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .fc-history-div{
        padding: 0px 56px 0px 56px;
        margin-bottom: 68px;

        .fc-history-title{
            margin-bottom: 36px;
            color: #000;
            font-family: 'Noto Sans Thai';
            font-size: 23px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }
    }
}

.final-contract-reject-modal{
    text-align: left;
    .fc-reject-header{
        color: #000;
        font-family: 'Noto Sans Thai';
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    .fc-reject-note-label{
        color: #09101D;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .fc-reject-btn-div{
        margin-top: 20px;
        border-top: 1px solid #DADEE3;
        padding: 18px 4px 8px 4px;

        .fc-reject-submit{
            border-radius: 6px;
            background: #0778FF;
            color: #FFF;
            font-family: 'Noto Sans Thai';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;

        }

        .fc-reject-cancel{
            margin-right: 16px;
            border-color: #FFF;
            background-color: #FFF;
            color: #9098A1;
            font-family: 'Noto Sans Thai';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }

    }



}