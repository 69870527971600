$fontFamily: Noto Sans Thai;

.page-content-assign {
    background: #FFFFFF;
    border-radius: 24px;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
    padding: 48px 56px;
}

.header-container {
    padding-top: 20px;
}

.work-area-container {
    padding: 0 56px;
}

.select-container {
    opacity: 1 !important;
    width: 100%;
}

.cancel-request-container {
    background: #FFF4EC;
    border-radius: 24px;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
    padding: 48px 56px;
    margin-bottom: 16px;
}

.breadcrumb {
    color: #09101D;
    font-family: $fontFamily;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.header-content {
    margin: 20px 40px;
    color: #000000;
    font-family: $fontFamily;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.header-label-pdf {
    padding-top: 3px;
}

.body-content {
    margin: 0 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    font-family: $fontFamily;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    opacity: 0.8;
}

.content-name {
    color: #545D69;
    margin-bottom: 0;
}

.content-val {
    color: #09101D;
}

.dashed-line {
    border: 1px dashed #DADEE3;
    margin: 20px 40px;
}

.input-form-control {
    display: flex;
    width: 246px;
    flex-direction: column;
    align-items: flex-start;
}

.warning-content {
    display: flex;
    gap: 8px;
}

.warning-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    color: #B95000;
}

.select-menu {
    opacity: 1 !important;
    z-index: 100 !important;
    min-width: 400px;
}

.selected-item {
    display: flex;
    padding: 6px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 24px;
    background: rgba(7, 120, 255, 0.10);
    color: #09101D;
    font-family: $fontFamily;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: auto !important;
    margin-right: 3px;
}

.add-item-group {
    display: flex;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;
}

.input-btn-inside {
    padding-right: 35px !important;
    box-sizing: border-box;
}

.no-margin {
    margin: 0 !important;
}

.form-feedback {
    text-align: left;
    font-family: $fontFamily;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.comment-assign {
    resize: both;
    min-width: 600px;
    min-height: 100px;
    max-width: 1000px;
    max-height: 500px;
}

.whitespace-container {
    padding: 32px 0;
}

.history-container {
    margin: 10px 40px;
}

.btn-base {
    font-family: $fontFamily;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 24px !important;
    display: flex !important;
    padding: 12px 16px !important;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 8px !important;
    box-shadow: none !important;
}

.btn-group {
    display: inline-flex;
    align-items: center;
    gap: 16px;
}

.btn-back {
    color: #858C94 !important;
    background-color: #FFFFFF !important;
    border: none !important;
}

.btn-cancel {
    color: #858C94 !important;
    background-color: transparent !important;
    border: none !important;
}

.btn-approve-reject {
    color: #FFFFFF !important;
    background-color: #B95000 !important;
    border: none !important;
}

.btn-reject {
    color: #B95000 !important;
    background-color: #FFFFFF !important;
    border: none !important;
}

.btn-return {
    color: #0778FF !important;
    background-color: #FFFFFF !important;
    outline: 1px solid #0778FF !important;
}

.btn-submit {
    color: #FFFFFF !important;
    background-color: #0778FF !important;
    border: none !important;
}

.btn-disable {
    color: #FFFFFF !important;
    background-color: #9098A1 !important;
    border: none !important;
}

.btn-add-item {
    color: #6C757D !important;
    background: transparent !important;
    position: absolute;
    border: none !important;
    outline: none;
    box-shadow: none !important;
}

.modal-header-container {
    color: #000000;
    font-family: $fontFamily;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.modal-body-container {
    color: #09101D;
    font-family: $fontFamily;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 2px;
}

.modal-content-label {
    display: flex;
    padding: 0 16px;
    align-items: center;
}

.modal-content-input {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
    min-height: 100px;
    max-height: 500px;
}

.modal-attachment {
    display: flex;
    padding-top: 12px;
}