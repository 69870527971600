.history-request{
    .hr-group-title{
        color: #0778FF;
        font-family: 'Noto Sans Thai';
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .hr-desc-container{
        display: grid;
        grid-template-columns: 40px auto 40%;
        grid-template-rows: auto;
        grid-template-areas: 
            "title-icon title-detail title-time"
            "desc-icon desc desc2";
        grid-row-gap: 8px;
        margin-bottom: 8px;
    }

    .hr-desc-item-title-icon{
        grid-area: title-icon;
        justify-self: center;
        align-self: center;
        
        .hr-title-icon-circle{
            width: 16px;
            height: 16px;
            background-color: rgba(7, 120, 255, 0.10);
            border: solid 1px rgba(7, 120, 255, 0.10);
            border-radius: 100%;
        }

    }

    .hr-desc-item-title-detail{
        grid-area: title-detail;
        justify-self: start;
        align-self: center;
        cursor: pointer;

        p{
            margin-bottom: 0px;
            color: #09101D;
            font-family: 'Noto Sans Thai';
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
        }

        i{
            margin-left: 8px;
            margin-bottom: 0px;
            font-size: 20px;
            line-height: 28px;
            color: #858C94;
        }
    }

    .hr-desc-item-title-time{
        grid-area: title-time;
        justify-self: start;
        align-self: center;

        p{
            margin-bottom: 0px;
            color: #545D69;
            font-family: 'Noto Sans Thai';
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }
    }

    .hr-desc-item-desc-icon{
        height: 100%;
        grid-area: desc-icon;
        justify-self: center;
        align-self: start;
        background-color: rgba(7, 120, 255, 0.10);

        .hr-desc-icon-line{
            width: 2px;
        }
    }

    .hr-desc-item-desc{
        grid-area: desc;
        justify-self: start;
        align-self: start;

        .hr-item-desc-type{
            margin-bottom: 8px;
            color: #09101D;
            font-family: 'Noto Sans Thai';
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
        }

        .hr-item-desc-comment{
            margin-bottom: 8px;
            color: #545D69;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;

            .hr-item-desc-comment-label{
                vertical-align: top;
            }

            .hr-item-desc-comment-span{
                display: inline-block;
            }

            .hr-item-desc-comment-files{
                margin-top: 10px;
            }
        }

        .hr-item-desc-comment-show{
            display: block;
        }

        .hr-item-desc-comment-hide{
            display: none;
        }

        .hr-item-desc-by{
            margin-bottom: 15px;
            color: #545D69;
            font-family: 'Noto Sans Thai';
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .hr-desc-item-desc2{
        grid-area: desc2;
        justify-self: start;
        align-self: center;
    }

}