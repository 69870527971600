.login{
    margin: 0;
    padding: 0;

    .login-container {
        width: 100%;
        margin: 0 auto;
    }

    .login-left-content {
        height: 100vh;
        width: 50%;
        float: left;
        background-color: #0778FF;
    }

    .login-right-content {
        height: 100vh;
        width: 50%;
        float: right;
        background-color: #F4F6F9;
    }

    .login-content{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .login-left-img{
        height: 100%;
        width: 80%;
        object-fit: contain;
    }

    .login-title{
        color: #000;
        text-align: left;
        margin: 0 10px 74px 10px;
        // margin-bottom: 74px;
        font-family: 'Noto Sans Thai';
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        // line-height: 32px;
        line-height: 38px;
    }

    .login-btn{
        margin-bottom: 16px;
        height: 40px;
        width: 368px;
        padding: 0px 52px;
        align-items: center;
        border-radius: 8px;
        background-color: #FFF;
        
        img{
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }
        
        label{
            cursor: pointer;
            margin-bottom: 0px;
            color: #9098A1;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }
    }

    @media (max-width: 767.98px) {
        .login-left-content {
            display: none;
        }

        .login-right-content {
            float: none;
            width: 100%;
        }
    }
}