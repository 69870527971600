@media (min-width: 1024.1px) {
    .container-fluid-rooms {
        max-width: 97%;
        margin: 0 auto;
    }
}

@media (max-width:575.98px) {
    .container-fluid-rooms {
        padding: 0;
    }
}

// @media (min-width: 1024.1px) {
//     .rooms-search {
//         margin-left: 1rem;
//         margin-right: 1rem;
//     }

//     .rooms-lists{
//         margin-left: 0.5rem;
//     }
// }

// @media (max-width:575.98px) {
//     .rooms-search {
//         margin-left: 0.1rem;
//         margin-right: 0.1rem;
//     }

//     .rooms-lists{
//         margin-left: 0;
//     }
// }

.rooms{
    padding: calc(45px + 1.5rem) calc(1.5rem / 2) 60px calc(1.5rem / 2);

    .rooms-search{
        .rooms-title-page{
            color: #000;
            font-family: 'Libre Caslon Display';
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        
        .rooms-title-date{
            color: #9B8F70;
            font-family: 'Libre Caslon Display';
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        
        .rooms-search-div{
            min-height: 65px;
            border-radius: 20px;
            border: 1px solid #9B8F70;
            background: #FFF;
        }
        
        .rooms-search-label{
            color: #806852;
            font-family: 'Libre Caslon Display';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        
        .rooms-search-button{
            width: 89px;
            height: 48px;
            flex-shrink: 0;
            border-radius: 20px;
            background-color: #9B8F70;
            color: #FFF;
            font-family: 'Libre Caslon Display';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    
        .rooms-search-button:active {
            transform: scale(0.98);
            box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
        }
        
        .room-search-input-label{
            // background-color: transparent;
            // border-color: transparent;
            color: #000;
            padding: 0.5rem 0rem;
            font-family: 'Libre Caslon Display';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        
        .room-search-input-label-icon{
            color: #9B8F70;
            font-size: 16px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
        }
        
        .room-search-input-ddl{
            color: #000;
            font-family: 'Libre Caslon Display';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    
    .rooms-lists{
        .rooms-grid{
            overflow-x: auto;
            white-space: nowrap;
            -webkit-overflow-scrolling: touch;
        }
        
        .rooms-grid-detail{
            display: inline-block;
            margin: 50px 20px 30px 5px;
        }
        
        .rooms-card{
            width: 311px;
            height: 418px;
            flex-shrink: 0;
        
            border-radius: 24px;
            border: 1px solid #FFF;
            background: #FFF;
            box-shadow: 0px 4px 17px 11px rgba(155, 143, 112, 0.13);
        }
        
        .rooms-card-img{
            width: 272px;
            height: 234px;
            flex-shrink: 0;
            border-radius: 16px;
            background: #FFF;
        }
        
        .rooms-card-name{
            color: #000;
            font-family: 'Libre Caslon Display';
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        
        .rooms-card-location{
            color: #9B8F70;
            font-family: 'Poppins';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        
        .rooms-card-size{
            color: #9B8F70;
            font-family: 'Poppins';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        
        .rooms-card-type{
            color: #9B8F70;
            font-family: 'Poppins';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}