.memo-title{
    margin-bottom: 10px;
    color: #000;
    font-family: 'Noto Sans Thai';
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.memo{
    .memo-select-div{
        border-bottom: 2px dashed #DADEE3;
        padding-bottom: 22px;
        margin-top: 17px;
        margin-bottom: 32px;
    }

    .memo-select-header{
        margin-bottom: 24px;
        color: #000;
        font-family: 'Noto Sans Thai';
        font-size: 23px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .memo-select-title{
        margin-bottom: 16px;
        color: #2C3A4B;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .memo-radio-input{
        border-color: #0778FF;
    }

    .memo-radio-label{
        color: #09101D;
        font-family: 'Noto Sans Thai';
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .memo-radio-label-desc{
        margin-bottom: 16px;
        color: #2C3A4B;
        font-family: 'Noto Sans Thai';
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
    }

    .memo-radio-space{
        margin-top: 24px;
    }
    
    .memo-file-header{
        margin-bottom: 24px;
        color: #000;
        font-family: 'Noto Sans Thai';
        font-size: 23px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .memo-file-download-label{
        margin-bottom: 14px;
        color: #2C3A4B;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .memo-file-upload-label{
        margin-top: 18px;
        margin-bottom: 14px;
        color: #2C3A4B;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .memo-note-div{
        margin-top: 18px;
        margin-bottom: 68px;
    }

    .memo-note-label{
        color: #09101D;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .memo-note-input{
        border-radius: 8px;
        border: 1px solid #858C94;
        background: #FFF;
    }

    .memo-agreement-div{
        border-bottom: 2px dashed #DADEE3;
        padding-bottom: 22px;
        margin-bottom: 32px;
    }

    .memo-agreement-label{
        margin-bottom: 14px;
        color: #2C3A4B;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    .memo-btn-back{
        border-color: #FFF;
        background-color: #FFF;
        color: #858C94;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
    
    .memo-btn-submit{
        border-color: #0778FF;
        border-radius: 8px;
        background-color: #0778FF;
        color: #FFF;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
}