.register{
    // width: 100vw;
    min-height: 100vh;
    background-image: url("../../images/register-img.png");
    background-repeat: no-repeat;
    background-position: 98% 96%;

    .row{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .register-header{
        padding: 0 144px;
        max-width: 100%;
        height: 100px;
        border-bottom: 1px solid #E5E5E5;
        .register-header-title{
            color: #000;
            font-family: 'Noto Sans Thai';
            font-size: 33px;
            font-style: normal;
            font-weight: 600;
            line-height: 36px;
        }

        .register-header-back{
            font-size: 24px;
            margin-right: 16px;
            cursor: pointer;
        }
    
        @media (max-width: 767.98px) {
            padding: 0 5px;
        }
    }

    .register-detail{
        max-width: 100%;
        margin-top: 80px;
        .register-detail-title{
            color: #000;
            font-family: 'Noto Sans Thai';
            font-size: 23px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }

        .register-detail-save{
            width: 80%;
            margin-top: 22px;
            background-color: #0778FF;
            border-radius: 4px;
            color: #FFF;
            font-family: 'Noto Sans Thai';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }

        @media (max-width: 767.98px) {
            margin-top: 30px;
            padding: 0 5px;
        }
    }
}