.summary-request{
    .sr-title{
        margin-bottom: 10px;
        color: #000;
        font-family: 'Noto Sans Thai';
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
    
    .sr-msg-contract-completed{
        padding: 32px 56px;
        margin-bottom: 24px;
        border-radius: 24px;
        background-color: rgba(61, 213, 152, 0.15);

        .sr-msg-contract-completed-title{
            margin-bottom: 0px;
            color: #09101D;
            font-family: 'Noto Sans Thai';
            font-size: 23px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;

            i{
                margin-right: 8px;
                font-size: 24px;
                color: #3DD598;
            }

        }
    }

    .sr-req-desc{
        padding: 48px 56px;
        border-radius: 24px;
        background-color: #FFF;
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);

        .sr-req-desc-title{
            border-bottom: 2px dashed #DADEE3;
            margin-bottom: 16px;
            p{
                margin-bottom: 16px;
                color: #000;
                font-family: 'Noto Sans Thai';
                font-size: 23px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
            }
        }

        .sr-req-desc-workarea{
            .sr-red-desc-contract-label{
                margin-bottom: 8px;
                color: #545D69;
                font-family: 'Noto Sans Thai';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            .sr-red-desc-contract-detail{
                margin-bottom: 16px;
                color: #09101D;
                font-family: 'Noto Sans Thai';
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
            }

            .sr-red-desc-file-final-contract-label{
                margin-top: 18px;
                margin-bottom: 12px;
                color: #545D69;
                font-family: 'Noto Sans Thai';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            .sr-red-desc-file-memo-label{
                margin-top: 18px;
                margin-bottom: 12px;
                color: #545D69;
                font-family: 'Noto Sans Thai';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            .sr-red-desc-file-signed-label{
                margin-bottom: 12px;
                color: #545D69;
                font-family: 'Noto Sans Thai';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }

    .sr-history{
        padding: 0px 56px 0px 56px;
        margin-top: 32px;
        margin-bottom: 68px;

        .sr-history-title{
            padding-top: 32px;
            border-top: 2px dashed rgba(7, 120, 255, 0.50);
            margin-bottom: 36px;
            color: #000;
            font-family: 'Noto Sans Thai';
            font-size: 23px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }
    }

    .sr-btn-back{
        border-color: #FFF;
        background-color: #FFF;
        color: #858C94;
        font-family: 'Noto Sans Thai';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }

    .sr-req-desc-form{
        .page-content-assign{
            padding-right: 10px;
            padding-left: 10px;
            padding-top: 30px;
            padding-bottom: 15px;
        }
    }

}